<template>
  <!-- <div class="hello">
    <v-btn @click="$router.push('/hehe')"> to hehe</v-btn>
    <v-btn @click="login">获取</v-btn>
    <v-btn @click="$cookies.remove('access_token')">清除</v-btn>
  </div>-->
  <v-layout max-width>
    <div class="login flex-center flex-column max-width" style="height:100vh;">
      <div class="flex-1 flex-column flex-center max-width position-relative" style=" z-index:2;">
        <div
          class="flex-justify-between"
          style="min-height:550px;box-shadow:0 0 6px 1px #ddd;background:#fff;"
        >
          <div
            class="grey lighten-5 flex-shrink-0 radius-0 flex-column flex-center py-4 px-6"
            style="border-right: 1px dashed #eee !important;width:400px;padding:16px 24px;"
          >
            <img class src="@/assets/logo3.png" width="200">
            <div
              class="mb-2 pb-2 mt-2 flex-center"
              style="margin:8px 0;padding-bottom:8px; border-bottom: 1px dashed rgb(221, 221, 221);"
            >
              <img class src="@/assets/logo4.png" width="300">
            </div>
            <div
              class="font-weight-bold title mb-4 mt-6"
              style="margin-bottom:8px;margin-bottom: 16px;font-weight:700;font-size:1.25rem;line-height:2rem;"
            >CTMS前台管理系统</div>
            <p
              class="body-2 grey--text"
              style="text-indent: 2em;font-size:1em;"
            >为临床试验提供项目管理、筛选受试者、建立随访计划、制定随访事件、并监管受试者的随访信息与书写受试者的随访病历，以保证各试验项目的顺利开展以及数据的完整性</p>
            <!-- <div
              class="body-2 mt-12 grey--text"
              style="margin-top:48px;font-size:0.85rem;"
            >v1.2.06.22-beta-20200101</div> -->
          </div>
          <div
            class="py-4 px-10 flex-shrink-0 flex-center"
            style="padding:32px 40px;min-width:500px;"
            min-width="500"
          >
            <div class="flex-center flex-1" style>
              <form class="flex-column max-width" @submit.prevent="submit">
                <!-- margin-bottom:8px; -->
                <div
                  class="headline font-weight-bold"
                  style="font-weight:700;font-size:1.5rem;line-height:2rem;margin-bottom:8px;"
                >修改密码</div>
                <div
                  class="body-2 mb-12 grey--text flex-y-center flex-justify-between"
                  style=""
                >
                  <span>首次登录密码修改</span>
                </div>
                <div>
                  <div
                    class="flex-justify-between default-input"
                    style="margin-bottom:28px;height:56px;"
                  >
                    <!-- <a-input required class="flex-1  subtitle-1 max-height" placeholder="用户名"
                  style="height:100%;border:none;background:rgba(0, 0, 0, 0.06);padding-left:24px;"  v-model="form.username" @pressEnter="submit"/>-->
                    <input
                      v-model="form.username"
                      required
                      class="flex-1 subtitle-1 max-height"
                      placeholder="原密码"
                      style="height:100%;padding-left:24px;"
                      @keyup.enter="submit"
                    >
                  </div>
                  <div
                    class="flex-justify-between default-input"
                    style="height:56px;margin-bottom:28px;"
                  >
                    <!-- <a-input required class="flex-1 subtitle-1" type="password" placeholder="密码"
                  style="height:100%;border:none;background:rgba(0, 0, 0, 0.06);padding-left:24px;" v-model="form.password" @pressEnter="submit"/>-->
                    <input
                      v-model="form.password"
                      required
                      class="flex-1 subtitle-1"
                      type="password"
                      placeholder="新密码"
                      style="height:100%;padding-left:24px;"
                      @keyup.enter="submit"
                    >
                  </div>
                  <div
                    class=" flex-1 flex-justify-between default-input"
                    style="height:56px;margin-bottom:28px;"
                  >
                    <input
                      v-model="form.captcha"
                      required
                      class="flex-1 subtitle-1"
                      type="text"
                      placeholder="确认新密码"
                      style="height:100%;padding-left:24px;"
                      @keyup.enter="submit"
                    >

                  </div>
                  <div class="flex-justify-between mb-4">
                    <div class=" flex-1 pr-2 " style="height:44px;">
                      <v-btn
                        :loading="btnLoading"
                        class="max-width"
                        style="height:100%;background:#1890ff;color:#fff;"
                        @click="submit"
                      >提交</v-btn>
                      <!--1976d2 1890ff -->
                    </div>
                    <div class=" flex-1 ml-2" style="height:44px;">
                      <v-btn
                        :loading="btnLoading"
                        class="max-width"
                        style="height:100%;background:#1890ff;color:#fff;"
                        @click="$router.replace({path:'/login'})"
                      >返回登录</v-btn>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      form: {
        username: 'admin',
        password: '123456',
        remember_me: false,
        checkKey: ''
      },
      btnLoading: false,
      randomImage: '',
      time: ''
    }
  },
  mounted() {
    this.getRandomImage()
  },
  methods: {

    getRandomImage() {
      this.form.checkKey = new Date().getTime()
      this.$http.get(this.$api.randomImage + '/' + this.form.checkKey, {}).then(res => {
        if (res.code === 0) {
          this.randomImage = res.result || ''
        }
      })
    },
    // login(){
    //   console.log('a')
    //       this.$http.post(this.$api.login,{"username":"xuzhaosi","password":"Jeeyor2020","remember_me":true}).then(res=>{
    //       this.$cookies.set('access_token',res.data.access_token)
    //       console.log(this.$cookies.get('access_token'))
    //       console.log(res)
    //       }).catch(error=>{
    //       console.log(error)
    //       })
    // },
    async submit() {
      // this.$Confirm.show({
      //   title:'自定义标题'
      // }).then(res=>{
      //     console.log(res)
      // })
      // this.$Confirm({
      //   title:'自定义标题'
      // }).then(res=>{
      //     console.log(res)
      // })

      this.btnLoading = true
      this.$http.post(this.$api.login, { data: this.form }).then(res => {
        if (res.code === 0 || res.code === 200) {
          // this.$cookies.set('access_token',res.result.token)
          localStorage.setItem('access_token', res.result.token)
          // this.$cookies.set('access_token',res.result.token,{expire:7})
          //
          return this.$router.replace({ path: '/' })
          // let path = '/'
          // this.$cookies.set('access_token', res.data.access_token, {
          //   path: '/',
          //   maxAge: 60 * 60 * 24 * 7
          // })
          // this.$cookies.set('login_time', new Date(), {
          //   path: '/',
          //   maxAge: 60 * 60 * 24 * 7
          // })
          // // if (this.form.remember_me) this.$cookies.set('user', this.form.username)
          // if (this.form.remember_me){
          //   this.$cookies.set('user', this.form.username)
          //   this.$cookies.set('username', this.form.username, {
          //     path: '/',
          //     maxAge: 60 * 60 * 24 * 30
          //   })
          //   this.$cookies.set('password', this.form.password, {
          //     path: '/',
          //     maxAge: 60 * 60 * 24 * 30
          //   })
          //   this.$cookies.set('remember_me', true, {
          //     path: '/',
          //     maxAge: 60 * 60 * 24 * 30
          //   })
          // }else{
          //   this.$cookies.get('remember_me')&&this.$cookies.remove('remember_me')
          //   this.$cookies.get('username')&&this.$cookies.remove('username')
          //   this.$cookies.get('password')&&this.$cookies.remove('password')
          // }
          // console.log(this.$store.state.loginRoute);
          // if (this.$store.state.loginRoute.name !== 'login') {
          //     path = this.$store.state.loginRoute.path || '/'
          // }
          // return this.$router.replace('/')
          //  path = this.$store.state.loginRoute.path || '/'
          //  path = '/'

          // router.replace({path:'./login'})
        }
      }).catch(() => {
        this.btnLoading = false
        // console.log(this.$snackbar)
        // this.$snackbar.start({
        //     text: '这个这个',
        //     color: '#4CAF50'
        // });
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.login {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      filter: blur(0);
      background-position: center center;
      background-size: cover;
      background-image: url("../../assets/login-background1.jpg");
      /*background-color: #031337;*/
      transition: all .75s ease;
    }

    &::before {
      filter: blur(3px);
    }
  }

  .login-form {
    width: 600px;
    /*width: 100%;*/
    padding: 0 16px;
    z-index: 2;
  }
.login-main {
  min-width: 260px;
  width: 500px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: #fff;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
    line-height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
